import React from 'react';
import IconInstagram from '../Images/Icons/instagram.svg';
import IconX from '../Images/Icons/x.svg';

import './LandingPage.css';


export default function LandingPage() {
  return (
    <div className="LandingPage">
      <div className="LandingPage-Background" />

      <div className="LandingPage-Title">
        Galleypot Games
      </div>

      <div className="LandingPage-Social-Icons-Container">
        <a
          className="LandingPage-Social-Icon"
          href="https://instagram.com/galleypot.games"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={IconInstagram}
            alt="Galleypot Games on Instagram"
          />
        </a>
        <a
          className="LandingPage-Social-Icon"
          href="https://x.com/galleypot"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={IconX}
            alt="Galleypot Games on X"
          />
        </a>
      </div>

      <div className="LandingPage-Copyright-Container">
        &copy; 2024 Galleypot Games, all rights reserved.
      </div>
    </div>
  );
}
